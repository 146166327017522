import { ArrowForward, Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";

function splitString(str: string, lineLength: Number) {
  let arr = str?.split(" ");
  let result: any = [];
  let subStr = arr[0];
  for (let i = 1; i < arr.length; i++) {
    subStr = subStr.replace(",", ""); //remove comma
    let word = arr[i];
    if (subStr.length + word.length + 1 <= lineLength) {
      subStr = subStr + " " + word;
    } else {
      result.push(subStr);
      subStr = word;
    }
  }
  if (subStr.length) {
    result.push(subStr);
  }

  if (result && result.length > 0) {
    for (var i = 0; i < result.length; i += 2) {
      if (i > 0) {
        result.splice(i, 0, "  ");
      }
    }
  }

  result.push("  ");
  result = result.join("\n");

  return result;
}

export default function Home({
  rawText,
  setRawText,
}: {
  rawText: any;
  setRawText: any;
}) {
  const [formatText, setFormatText] = React.useState();
  const [slideLength, setSlideLength] = React.useState(0);
  const [savingFile, setSavingFile] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  const handleNameChange = (event: any) => {
    setFileName(event.target.value);
  };
  const [lineLength, setLineLength] = React.useState(35);

  const handleSliderChange = (event: any, newValue: any) => {
    setLineLength(newValue);
  };

  const handleChange = (event: any) => {
    setRawText(event.target.value);
  };

  const openSaveModal = () => {
    setSavingFile(true);
  };

  const closeSaveModal=()=>{
    setFileName("")
    setSavingFile(false);
  }

  const download = () => {
    const blob = new Blob([formatText as any], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = fileName || "Song";
    link.href = url;
    link.click();
    setSavingFile(false)
  };

  const translate = () => {
    if (rawText) {
      var arrayOfLines = rawText?.split("\n");
      var values: any = Array.from(new Set(arrayOfLines));
      var formattedValues: any = [];
      let newLineCount = 0;

      if (values && values?.length > 0) {
        values.forEach((textLine: string, index: any) => {
          let previousLine = formattedValues[index - 1];

          let formattedLine = textLine;
          const hasEndSpace =
            previousLine && previousLine?.length
              ? previousLine[previousLine?.length - 2] == "''"
              : false;

          let hasNoSpace = previousLine && !previousLine?.includes("\n");

          ///remove brackets from line
          if (textLine && textLine?.length > 0) {
            formattedLine = textLine.replace(/\s*\(.*?\)\s*/g, "");
            if (formattedLine?.length > lineLength) {
              formattedLine = splitString(formattedLine, lineLength);
              newLineCount += 1;

              if (previousLine) {
              }

              if (hasNoSpace) {
                formattedLine = "\n" + formattedLine;
                newLineCount += 1;
              }
            } else {
              hasNoSpace =
                previousLine &&
                previousLine?.length < lineLength &&
                !previousLine?.includes("\n");
              if (hasNoSpace) {
                let currArrayLine = formattedLine?.split(" ");

                currArrayLine?.push("\n");
                newLineCount += 1;

                formattedLine = currArrayLine?.join(" ");
              } else {
              }
            }
          }

          formattedValues.push(formattedLine);
        });
      }

      formattedValues = formattedValues.filter(Boolean); ///remove spaces
      const reformat = formattedValues?.join("\n");

      setFormatText(reformat);
      setSlideLength(newLineCount);
    }
  };

  return (
    <Container
      style={{ width: "100%", maxWidth: "100vw" }}
      sx={{ padding: { sm: "0px 120px" }, marginTop: 5 }}
    >
      <Grid container spacing={2}>
        <Grid item lg={5} xs={12}>
          <Paper
            elevation={1}
            style={{
              padding: "20px 20px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                background: "#fff",
                outline: "none",
                border: "none",
              }}
              fullWidth
              id="outlined-multiline-static"
              label="Lyrics/Notes"
              multiline
              variant="standard"
              value={rawText}
              maxRows={20}
              focused={true}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Paste your favourite lyrics or notes here!"
              InputProps={{ disableUnderline: true }}
            />
          </Paper>
        </Grid>
        <Grid item lg={2} style={{ margin: "60px 0px" }} xs={12}>
          <Typography id="input-slider" gutterBottom>
            Adjust precision
          </Typography>

          <div>
            <Slider
              value={lineLength ? lineLength : 35}
              onChange={handleSliderChange}
              valueLabelDisplay="off"
              min={0}
              max={60}
              aria-labelledby="input-slider"
            />
          </div>

          <div style={{ height: 20 }} />
          <Button
            variant="outlined"
            onClick={translate}
            endIcon={<ArrowForward />}
          >
            Format
          </Button>

          <div style={{ height: 20 }} />
          <Typography component="div" sx={{ flexGrow: 1 }}>
            Slide length: {slideLength}
          </Typography>
          <div style={{ height: 20 }} />
          <Button
            sx={{ background: "#FF4500" }}
            variant="contained"
            disabled={!formatText}
            onClick={openSaveModal}
            endIcon={<Download />}
          >
            Download
          </Button>
        </Grid>
        <Grid item lg={5} xs={12}>
          <Paper
            elevation={1}
            style={{
              padding: "20px 20px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                background: "#fff",
                outline: "none",
                border: "none",
              }}
              id="outlined-multiline-static"
              label={formatText ? "" : "Formatted Text"}
              multiline
              focused
              defaultValue={formatText}
              maxRows={20}
              placeholder=""
              disabled
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={savingFile}
        onClose={closeSaveModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"File Name"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Please enter file name
          </DialogContentText> */}
          <TextField
            error={false}
            id="filename"
            placeholder="Please enter file name"
            value={fileName}
            sx={{ width: 300 }}
            focused
            onChange={handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSaveModal}>Cancel</Button>
          <Button onClick={download} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
