import axios from "axios";

export const getSongLyrics = async (song: any) => {
  let result: any = {};

  await axios({
    method: "get",
    url: `https://adblbuzy60.execute-api.us-east-2.amazonaws.com/Prod/getLyrics?title=${song}`,
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response: any) => {
      result = response.data?.lyrics
    })
    .catch((error: any) => {
      result = null
    });
  return result;
};


