import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import Home from './components/Home';
import { AppBar, Toolbar, Typography } from '@mui/material';

function App() {
  const [rawText, setRawText] = React.useState("");
  return (
    <div className="App">
     <NavBar rawText={rawText} setRawText={setRawText}/>
     <Home rawText={rawText} setRawText={setRawText}/>
     <AppBar position="fixed" color="primary" 
      sx={{
        boxShadow: "none",
        background: "#161616",
        padding: { sm: "0px 100px", },
        top: 'auto', bottom: 0,
        height:'40px'
      }}>
        <Toolbar sx={{ marginTop:'-13px'}}>
        <Typography variant="body2" component="div" sx={{ flexGrow: 1,textAlign:"right",}} style={{color:'grey'}}>
        😀 Developed by Caleb Tony-Enwin
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default App;
