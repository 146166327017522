import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, IconButton, Popper, TextField } from "@mui/material";
import { getSongLyrics } from "./searchService";
import { Close } from "@mui/icons-material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "37ch",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: 30,
    width: "100%",
  },
}));

export default function SearchBar({
  rawText,
  setRawText,
}: {
  rawText: any;
  setRawText: any;
}) {
  const [searching, setSearching] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const onSearch = async (event: any) => {
    const search = event.target.value;
    setSearchValue(search);
  };

  const clearSearch = () => {
    setSearchValue("");
    console.log(333);
  };
  const startSearch = async () => {
    setSearching(true);
    const lyrics = await getSongLyrics(searchValue);
    setRawText(lyrics);
    setSearching(false);
  };

  return (
    <Search>
      <TextField
        sx={{
          paddingLeft: "10px",
          marginTop: "4px",
          height: "inherit",
          paddingBottom: "5px",
          paddingRight: "0px",
          width: "90%",
        }}
        label=""
        inputMode="search"
        value={searchValue}
        placeholder="Search for song titles or lyrics or artists"
        variant="standard"
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
             startSearch()
            }
          }}
        onChange={onSearch}
        InputProps={{
          sx: { color: "#fff" },
          style: { color: "#fff" },
          disableUnderline: true,
          endAdornment: (
            <div
              style={{
                paddingLeft: "10px",
                marginTop: "0px",
                cursor: "pointer",
                marginBottom: "-10px",
                width: "50px",
                display: "flex",
              }}
            >
              {searching ? (
                <CircularProgress color="inherit" size={25} sx={{marginBottom:'10px'}} />
              ) : (
                <React.Fragment>
                      <span
                    style={{ display: "inline" }}
                    onClick={searching ? () => {} : startSearch}
                  >
                    <IconButton sx={{padding:'3px',marginBottom:'6px',color:'white'}}>
                      <SearchIcon />
                    </IconButton>
                  </span>
                  {searchValue && <span style={{ display: "inline" }} onClick={clearSearch}>
                    <IconButton sx={{padding:'3px',marginBottom:'6px'}}>
                      <Close
                        sx={{ fontSize: 24, fontWeight: "800", color: "black" }}
                      />
                    </IconButton>
                  </span>}

                
                </React.Fragment>
              )}
            </div>
          ),
        }}
      />
    </Search>
  );
}
