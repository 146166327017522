import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import { Button, Link } from "@mui/material";
import SearchBar from "./SearchBar";
import logo from '../assets/logo.png'

export default function NavBar({rawText, setRawText}:{rawText:any, setRawText:any}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          background: "#161616",
          padding: { sm: "0px 100px" },
        }}
      >
        <Toolbar>
          <img src={logo} height={25} style={{marginRight:'10px'}} />
          <Typography variant="h6" component="div" sx={{display:{}}}>
            ProText
          </Typography>
          <SearchBar rawText={rawText} setRawText={setRawText}/>
          <Box sx={{ flexGrow: 1 }} />
          {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button sx={{ color: "#2c3e50" }}>
              <Link
                sx={{
                  fontSize: "15px",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
                underline="none"
                href="#"
              >
                How to use
              </Link>
            </Button>

            <Button sx={{ color: "#2c3e50" }}>
              <Link
                sx={{
                  fontSize: "15px",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
                underline="none"
                href="#"
              >
                About
              </Link>
            </Button>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
